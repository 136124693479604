import React, { Component } from 'react';
// import WaterBlaze from "../../../images/water7.jpg";





class Lesserinternalbanner extends Component {
    render() {
        return (
            <div>
<section className="lesserinternalbanner" 
style={{ backgroundImage: `url(${this.props.backgrounder})` }}
>
  <div className="overfunk"></div>
  <div className="container">
      <div className="columns is-marginless">
          <div className="column is-half">
              <p className="topsubtitle">{this.props.subtitle}</p>
      <h1 className="midsize">{this.props.title}</h1>
      
      {this.props.subcopy ? <p className="undersubtitle">{this.props.subcopy}</p> : null}
          </div>
      </div>
  </div>
</section>
  

            </div>
        );
    }
}

export default Lesserinternalbanner;