import React from "react"

import "../style/bulma.css"
import "../style/bulma-timeline.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech-internal-livelab.css"
import HotWaterBack from "../images/datamanagementbanner.jpg";
import Newbannerpic from "../images/datapoint.jpg";

import Layout from "../components/layout"
import Lesserinternalbanner from "../components/internalpages/generalinternal/lesserinternalbanner"
import Datasecuritypageinternal from "../components/internalpages/datasecurity/datasecuritypageinternal"

import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"

const Datasecurity = () => (
  <Layout>
    <SEO title="Data Security" />
    <Lesserinternalbanner 
    backgrounder = {HotWaterBack}
    title="Data Security"
    subtitle="Data integrity and security is critical for our clients "
    subcopy=""
    newbannerpic={{Newbannerpic}}
    />
        <Datasecuritypageinternal />
   <Contactform />
   <Coolfooter />
  </Layout>
)

export default Datasecurity