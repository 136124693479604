import React, { Component } from 'react';
// import * as Icon from 'react-feather';
import { Link } from "gatsby";
import Datapoint from "../../../images/datapoint.jpg";
import Isopic from "../../../images/datasecuritybanner.jpg";
import Tuvlogo from "../../../images/tuv-logo.jpg";
import Isologo from "../../../images/iso27001_logo.png";
import IsoPDF from "../../../assets/ISMS-PP-13_Privacy_and_Personal_Data_Protection_Policy.pdf";


const styles = {
    isometricpic: {
        backgroundImage: `url(${Isopic})`
    },
    databackpic: {
        backgroundImage: `url(${Datapoint})`
    },
    datatuvpic: {
        backgroundImage: `url(${Tuvlogo})`,
        height: `7rem`,
        width: `7rem`,
        backgroundSize: 'cover'
    },
    dataisologopic: {
        backgroundImage: `url(${Isologo})`,
        height: `7rem`,
        width: `7rem`,
        backgroundSize: 'cover'
    }
};

class Aboutpageinternal extends Component {
    render() {

        return (
            <div>
                <section className="under-internalbanner graddown">
                    <div className="threeboxes">
                        <div className="container">
                            <div className="boxcard norise">
                                <div className="columns is-marginless">
                                    <div className="column is-9">
                                        <div className="datasecurity">
<h3>Security policies and procedures in place</h3>
<h5>Data integrity and security is critical for our clients – we have our security policies and procedures in place and achieved ISO 27001 accreditation in 2020.</h5>
<p>ISO 27001 is a framework for an Information Security Management System (ISMS) to address the management of information risks.</p>
<p>These information security controls protect the confidentiality, integrity, and availability of information with risk management as the primary driver of the control objectives.</p>
<p>The standard is structured logically around 14 security control families, 35 control objectives, and more than 114 individual controls.</p>
<hr/>
<h4>ISO/IEC 27001 is designed to cover much more than just IT and at Cool Harbour, we want to ensure that we:</h4>
<p>Systematically examine the organization's information security risks, taking account of the threats, vulnerabilities, and impacts;
Design and implement a coherent and comprehensive suite of information security controls and/or other forms of risk treatment (such as
risk avoidance or risk transfer) to address those risks that are deemed unacceptable; and</p>
<p>Adopt an overarching management process to ensure that the information security controls continue to meet the organization's information
security needs on an ongoing basis.</p>
<p>Put a business continuity plan and a system in place that ensures objectives and plans include data security and are reviewed
continuously for relevancy.</p>
<a href={IsoPDF}>Cool Harbour Limited Privacy and Personal Data Protection Policy</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns is-marginless">
                                    <div className="column is-9">
                                        <div className="columns is-marginless is-mobile">
                                             <div className="column is-one-third-desktop is-half-mobile is-paddingless"><figure style={styles.datatuvpic}></figure>

                                             </div>
                                             <div className="column is-one-third-desktop is-half-mobile is-paddingless"><figure style={styles.dataisologopic}></figure>

                                             </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
<section class="genpicbreak" style={styles.databackpic}>
<div class="thunderlay"></div>
</section>


            </div>
        );
    }
}

export default Aboutpageinternal;